import React from "react";
import Header from "./main/Header";
import Footer from "./main/Footer";
import { Link } from "react-router-dom";
import './privacy.css';

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <header class="header mt-4 mb-3">
        <div class="container">
          <h1>Privacy Policy</h1>
        </div>
      </header>
      <main class="container mt-4 mb-5">
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to ARJ Technologies! Your privacy is important to us, and
            this Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you visit our website or use our
            services.
          </p>
        </section>
        <section>
          <h2>1. Information We Collect</h2>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, email address, phone
              number, and payment details.
            </li>
            <li>
              <strong>Business Information:</strong> Details about your
              institution for service customization.
            </li>
            <li>
              <strong>Usage Information:</strong> Data like IP addresses,
              browser type, and page visits.
            </li>
            <li>
              <strong>Software-Specific Data:</strong> User-entered data in
              subscription-based software.
            </li>
            <li>
              <strong>Third-Party Integrations:</strong> Data from APIs like
              WhatsApp and payment gateways.
            </li>
          </ul>
        </section>
        <section>
          <h2>2. How We Use Your Information</h2>
          <p>We use your information for:</p>
          <ul>
            <li>Providing and improving our services.</li>
            <li>Processing payments and managing subscriptions.</li>
            <li>
              Sending updates, notifications, and marketing communications.
            </li>
            <li>Compliance with legal obligations.</li>
          </ul>
        </section>
        <section>
          <h2>3. Sharing and Disclosure of Information</h2>
          <ul>
            <li>
              <strong>With Service Providers:</strong> For service delivery.
            </li>
            <li>
              <strong>For Compliance:</strong> When required by law.
            </li>
            <li>
              <strong>With Your Consent:</strong> When explicitly agreed upon.
            </li>
          </ul>
        </section>
        <section>
          <h2>4. Data Security</h2>
          <p>
            We use encryption, secure servers, and audits to protect your data.
            However, no method of transmission over the internet is completely
            secure.
          </p>
        </section>
        <section>
          <h2>5. Cookies and Tracking</h2>
          <p>
            Our website uses cookies for better performance and analysis. You
            can manage cookies in your browser settings.
          </p>
        </section>
        <section>
          <h2>6. Contact Us</h2>
          <p>
            If you have questions or concerns about this policy, contact us at:
            <a href="mailto:support@arjtechnologies.com">
              support@arjtechnologies.com
            </a>
            .
          </p>
        </section>
      </main>
     
      <Footer />
    </>
  );
}

import React from 'react'
import Header from './main/Header'
import Footer from './main/Footer'
import { Link } from 'react-router-dom'

export default function About() {
    return (
        <>
            <Header />
            {/* about banner */}
            <section className="w3l-about-breadcrumb text-center">
                <div className="breadcrumb-bg breadcrumb-bg-about py-5">

                </div>
            </section>
            {/* //about banner */}
            {/* About Section */}
            <section className="w3l-content-6 pt-5" id="skills">
                <div className="content-6-mian">
                    <div className="container py-lg-5 py-md-4">
                        <div className="content-info-in row">
                            <div className="content-gd col-lg-6 pe-lg-5">
                                <img src="assets/img/securty3.png" alt="" className="img-fluid zoom-in-zoom-out" />
                            </div>
                            <div className="content-gd col-lg-6 mt-lg-0 mt-5 align-self ps-lg-5">
                                <h6 className="w3l-title-small mb-1">About Us</h6>
                                <h3 className="w3l-title-main mb-lg-4 mb-3">
                                    A Few words about us
                                </h3>
                                <p className="my-4 pe-lg-5">

                                    We are dedicated to delivering innovative IT solutions that empower businesses to succeed in the ever-evolving digital landscape. With a focus on quality, reliability, and customer satisfaction, we provide comprehensive services tailored to meet your unique needs.
                                </p>
                                <h5 className="w3l-subtitle mb-md-5 mb-4">
                                    With our extensive expertise, we deliver top-tier solutions tailored to elevate your business growth and success. Partner with us for unmatched service quality that drives results.

                                </h5>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="team-area" id="people">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="text-center">
                        <h6 className="w3l-title-small"> Our People</h6>
                        <h3 className="w3l-title-main"> Meet Our Team</h3>
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="col-lg-3 col-sm-6 col-xs-12">
                            <div className="single-team">
                                <div className="img-area">
                                    <img src="assets/img/J.png" className="img-fluid" alt="" />
                                    <div className="social">
                                        <ul className="list-inline">
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-pinterest" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h4>Mr.Jitesh Saini</h4>
                                    <p>Founder and CEO</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-sm-0 mt-5">
                            <div className="single-team">
                                <div className="img-area">
                                    <img src="assets/img/l.png" className="img-fluid" alt="" />
                                    <div className="social">
                                        <ul className="list-inline">
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h4>Mr.Laxman Saini</h4>
                                    <p>Flutter Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-lg-0 mt-5">
                            <div className="single-team">
                                <div className="img-area">
                                    <img src="assets/img/ab.png" className="img-fluid" alt="" />
                                    <div className="social">
                                        <ul className="list-inline">
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-pinterest" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h4>Mr.Abhishek Kumawat </h4>
                                    <p>Laravel Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-lg-0 mt-5">
                            <div className="single-team">
                                <div className="img-area">
                                    <img src="assets/img/v.png" className="img-fluid" alt="" />
                                    <div className="social">
                                        <ul className="list-inline">
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#url">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h4>Mr.Vishal Kumawat</h4>
                                    <p>FrontEnd Developer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* //Team Section */}
            <section className="w3l-covers-14 py-5">
                <div className="container py-md-5 py-4">
                    <div
                        className="banner-play-w3 covers14-text text-center mx-auto"
                        style={{ maxWidth: 780 }}
                    >
                        <h3 className="w3l-title-main text-white">
                            We Are Here To Demo School management Software.
                        </h3>
                        <div className="video-section mt-5 pt-lg-4">
                            {/*//video*/}
                            <Link
                                to={"/Software_demo"}
                                className="popup-with-zoom-anim play-view text-center pl-3"
                            >
                                <span className="video-play-icon">
                                    <span className="fa fa-play" />
                                </span>
                            </Link>
                            {/* dialog itself, mfp-hide class is required to make dialog hidden */}
                            <div className="zoom-anim-dialog mfp-hide">

                            </div>
                            {/*//video*/}
                        </div>
                    </div>
                </div>
            </section>
            {/* Company logos */}
            <section className="company-logos">
                <div className="container">
                    <div className="row justify-content-around ">


                        <div className="col-md-2 mt-5">
                            <a href="#url">
                                <img src="assets/img/utthan.png" alt="" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-2 text-center">
                            <a href="#url">
                                <img src="assets/img/fixa.png" alt="" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-2 mt-5">
                            <a href="#url">
                                <img src="assets/img/ubgot.png" alt="" className="img-fluid" />
                            </a>
                        </div>

                        <div className="col-md-2 mt-5">
                            <a href="#url">
                                <img src="assets/img/arj.png" alt="" className="img-fluid" />
                            </a>
                        </div>

                    </div>
                </div>
            </section>
            {/* //Company logos */}
            <Footer />
        </>

    )
}

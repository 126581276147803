import React from 'react'
import Header from './main/Header'
import Footer from './main/Footer'
import { Link } from 'react-router-dom'

export default function Services() {
    return (
        <>
            <Header />
            {/* Services banner */}
            <section className="w3l-about-breadcrumb text-center">
                <div className="breadcrumb-bg breadcrumb-bg-about3 py-5">

                </div>
            </section>
            {/* //Services banner */}
            <div className="service-section py-5">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-sm-6 item">
                            <div className="card">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link to={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s1.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Quality Control System
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-sm-0 mt-4 pt-sm-0 pt-2">
                            <div className="card">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link to={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s2.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Professional Achievement
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-lg-0 mt-4 pt-lg-0 pt-2">
                            <div className="card">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link to={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s3.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Mobile Apps Design
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-lg-0 mt-4 pt-lg-0 pt-2">
                            <div className="card">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link hto={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s4.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Social Media &amp; Strategy
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section */}
            <section className="about-section py-5">
                <div className="container py-lg-5 py-4">
                    <div className="title-heading-w3 mx-auto text-center mb-5 pb-xl-4">
                        <h3 className="w3l-title-main">
                            We run all kinds of IT services that vow your success
                        </h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-11.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Soft. Development</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-4.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Development</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-5.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url"> App Development</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/desgin1.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Design</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row justify-content-center">
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-3.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">UI/UX Design Service</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/graphic.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Graphic Design</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/security.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Hosting & Domain Service
                                        </a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/server.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Systems Management</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* //Services Section */}
            {/* technology Section */}
            <section className="new_tech_sec">
                <div className="container">
                    <h1 className='text-center'>Our Latest Technologies</h1>
                    <div className="row mt-3 mb-3">
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/react.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/android_226770.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/app-store_5977575.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/react_1183672.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/php.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/laravel.995x1024.png" alt="" width={65} />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4 mb-3">
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/node-js.909x1024.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/java_919854.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/python_1822899.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/mysql_919836.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/amazon_13170782.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/git_4494748.png" alt="" width={65} />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-3">
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/html-5_5968267.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/css-3_5968242.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/bs5.png" alt="" width={85} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/photoshop_5968520.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/illustrator_5968472.png" alt="" width={65} />
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-2">
                            <div className="card p-4 new_tech">
                                <img src="assets/img/js_5968292.png" alt="" width={65} />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* //technology Section */}
            <Footer />
        </>

    )
}
